ul li .self-request-form  form button{
    padding: 14px!important;
}

.self-request-form form label{
    color: #000;
    opacity: 0.8;
    font-size: 12px;
    padding: 0px;
}



.self-request-form form input{
    font-size: 14px;    
    border: 1px solid #DDDDDD;
    padding: 7px 10px;
}

.self-request-form{
    background: #FFFFFF;
    border: 1px solid #EBEBEB;
    box-sizing: border-box;
    border-radius: 7px;
    padding: 15px 30px;
}

.self-request-form form input::placeholder{
    color: #DDD;
}

.request-form-container > img{
    z-index: -1;
}
.request-form-container{
    padding: 32px 38px 1px 15px;
    position: relative;
    height: 100%;
    z-index: 1;
}
.request-form-container footer{
    position: absolute;
    width: 100%;
    left: 0;
    padding: 0 17px;
    bottom: 0;
}

button{
    width: 100%;
}


.request-form ul li button{
    padding: 8px!important;
    text-align: left;
    height: 48px;
}

.request-form ul li:last-child button, .self-request-form form button{
    padding: 12px!important;
}

.request-form ul li span{
    background-color: #F9FAFE;
    padding: 2px 15px;
}

.request-form-phonenumber .PhoneInput{
    border: 1px solid #DDDDDD;
    border-radius: 4px;
}
.request-form-phonenumber .PhoneInput input{
    border: none!important;
}

.request-form-phonenumber .PhoneInputCountry{
background-color: #F8F8F8;
padding: 10px;
}

.request-form-phonenumber .PhoneInput--focus input{
    outline: none;
} 