.signup-page{
    min-height: 100vh;
    background: #F9FAFE;
}

.signup-desc-container{   
    background-image: url("https://zluri-assets-new.s3.us-west-1.amazonaws.com/images/Frame+26(1).webp");
    height: 100%;
    padding-bottom: 30px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.signup-container{
    background-image: url("https://zluri-assets-new.s3.us-west-1.amazonaws.com/images/Frame+40.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.slide-item{
    outline: none!important;
}

.slick-dots.signup-desc-dots li button{
    background: #E5F1FF;
    opacity: 0.5;
    border-radius: 10px;
    padding: 5px 8px;
    width: auto;
    height: auto;
}

.slick-dots.signup-desc-dots li, .slick-dots.signup-desc-dots li.slick-active  {
    width: auto;
    height: auto;
}

.slick-dots.signup-desc-dots li.slick-active button{
    opacity: 0.8;
    padding: 5px 37px;
}

.slick-dots.signup-desc-dots li button:before, .slick-dots.signup-desc-dots li.slick-active button:before{
    content: none;
    width: 0;
    height: 0;
    padding: 0;
}


.signup-container{
    max-height: 100vh;
    height: 100vh;
    position: relative;
    overflow: auto;
}

.slide-item-image-container{
    min-width: 280px;
    max-width: 280px;
    max-height: 220px;
    min-height: 220px;
    position: relative;
    margin: 0 auto;
    overflow: hidden;
    
}

.slide-item-image-container img{
    max-width: 100%;
    width: 100%;
    max-height: 100%;
}

.slide-item-desc-container{
    width: 60%;
    margin: 0 auto;
}

.slide-item-desc-sub-container img{
    width: 50%;
    margin: 0 auto;
}

.sub-slider{
    width: 80%;
    margin: 0 auto;
    background: rgba(0, 0, 0, 0.2);
 border-radius: 11px;
}

.sub-slider .slick-arrow.slick-prev{
    margin-left: 5px;
    opacity: 0.3;
}

.sub-slider .slick-arrow.slick-next{
    margin-right: 5px;
    opacity: 0.3;
}

.footer-mobile{
    background: #fff;
    margin-top: 50px;
}

@media screen and (max-width: 767px) {
    .signup-desc-container{
        height: 100%;
        padding: 0;
    }
}