.invite-code input {
	font-size: 14px;
	border: 1px solid #dddddd;
	padding: 10px;
	width: 100%;
	border-radius: 4px;
	outline: none;
	margin-top: 5px;
	margin-bottom: 5px;
}

.invite-code input::placeholder {
	color: #ddd;
}

.invite-code button {
	height: 48px;
}
