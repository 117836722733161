@import url("https://fonts.googleapis.com/css2?family=Sora:wght@400;700&display=swap");
$spacer: 1rem !default;
$spacers: () !default;

$spacers: map-merge(
	(
		0: 0,
		1: $spacer * 0.25,
		2: $spacer * 0.5,
		3: $spacer,
		4: $spacer * 1.5,
		5: $spacer * 3,
		6: $spacer * 4,
		7: $spacer * 5,
		8: $spacer * 6.25,
		9: $spacer * 7.5,
		10: $spacer * 9.375,
	),
	$spacers
);

@import "~bootstrap/scss/bootstrap.scss";

$btn-padding-y: 7px;
$btn-padding-x: 10px;

body {
	font-family: "Sora", sans-serif;
	font-style: normal;
	font-weight: normal;
}

.btn {
	box-sizing: border-box;
	border-radius: 4px;
	padding: 7px 10px;
}

.btn-primary, .bg-primary {
	background-color: #2266e2;
}

.btn-primary-inverse {
	border: 1px solid #2266e2;
	color: #2266e2;
}

.btn-primary-inverse:hover {
	color: #2266e2;
}

.pointer {
	cursor: pointer;
}

.font-22 {
	font-size: 22px;
	line-height: 28px;
}

.font-12 {
	font-size: 12px;
	line-height: 15px;
}

.font-18 {
	font-size: 18px;
	line-height: 23px;
}

.font-11 {
	font-size: 11px;
	line-height: 14px;
}

.font-9 {
	font-size: 9px;
	line-height: 11px;
}

.font-8 {
	font-size: 9px;
	line-height: 10px;
}

.font-14 {
	font-size: 14px;
	line-height: 18px;
}

.font-13 {
	font-size: 13px;
	line-height: 20px;
}

.font-16 {
	font-size: 16px;
	line-height: 20px;
}

.font-10 {
	font-size: 10px;
	line-height: 13px;
}

ul {
	list-style: none;
	padding: 0;
}

.grey {
	color: #484848;
}

.grey-1 {
	color: #717171 !important;
}

.a-blue,
.a-blue:hover {
	color: #6bc1ff;
}

.green {
	color: #5fcf64;
}

.red {
	color: #dc3545;
}

.black {
	color: #000;
}
.black-1 {
	color: #222;
}

.white{
	color: #fff;
}

.primary-color {
	color: #2266e2;
}

.o-7 {
	opacity: 0.7;
}

.o-8 {
	opacity: 0.8;
}
.o-5 {
	opacity: 0.5;
}

.o-6 {
	opacity: 0.6;
}

.bold-700 {
	font-weight: 700;
}

.bg-white {
	background: #fff;
}

a {
	text-decoration: none;
}

::-webkit-scrollbar {
	width: 8px;
	height: 8px;
	background-color: #fdfdfd;
}

::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0,  0.1);
	box-shadow: inset 0 0 6px rgba(0, 0, 0,  0.1);
	background-color: #e5e5e5;
}

::-webkit-scrollbar-track {
	border-radius: 10px;
	background-color: #fdfdfd;
}
::-webkit-scrollbar-corner {
	border-radius: 10px;
}
